import domReady from '@roots/sage/client/dom-ready';
import 'jquery'
import 'bootstrap'
import { initSaneUI, initComponents } from 'sane-ui/lib/js/util/components.js'
import { initImgix  } from 'sane-ui/lib/js/util/lazyload.js'
import lazySizes from 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js'

lazySizes.cfg.init = false

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...

	// application code
	setTimeout(
		() => {
				initImgix()
				lazySizes.init()
				initSaneUI()

				// Only load the ones from the page. so * as components should be different.
				const $requiredComponents = $('[data-component]')
				const saneComponents = [
					// 'AnimatedType',
					'AnimatedCard',
					// 'PseudoSelect',
					'VideoPlayerFull',
					// 'VideoPlayer',
					// 'Toggle',
					// 'Toggleable',
					'ScrollTo',
					'ScrollToTop',
					// 'ScrollShowHide',
				]

				if ($requiredComponents.length > 0) {
					importModules( $requiredComponents, saneComponents )
						.then((components) => {
								initComponents(components, $requiredComponents)
						})
				}
			},
			window.app.environment === 'development' ? 1500 : 0
	)
});

const importModules = async ($requiredComponents, saneComponents) => {
	const components = {}
	const promises = $.map($requiredComponents, async(el) => {
		const componentName = $(el).data('component')

    var componentModule

		if (saneComponents.includes(componentName)) {
			componentModule = await import(`sane-ui/lib/js/components/${componentName}.js`)
		} else {
			componentModule = await import(`./components/${componentName}.js`)
		}

		components[componentName] = componentModule.default
	})

	await Promise.all(promises);
	return components
}

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
